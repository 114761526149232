import React from 'react';
import ReactMarkDown from 'react-markdown';
import raw from 'raw.macro';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    opacity: 0.84,
    padding: theme.spacing(10, 0, 5),
    background: theme.palette.common.black,
    color: theme.palette.background.default,

    '& h3, h4, p, ul': {
      margin: theme.spacing(1, 0),
    },

    '& a': {
      color: theme.palette.background.default,

      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
  content: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    minHeight: '120vh',
  },
}));

function Privacy() {
  const classes = useStyles();
  const text = raw('../assets/docs/privacy-policy.md');

  return (
    <header className={classes.root}>
      <div
        className={classes.content}
      >
        <ReactMarkDown source={text} />
      </div>
    </header>
  );
}

export default Privacy;
