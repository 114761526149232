import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';

import clsx from 'clsx';

import {
  Ruby,
  Rails,
  Python,
  Django,
  Javascript,
  ReactJs,
  Amazonaws,
  Heroku,
  Postgresql,
  Linkedin,
  Github,
  Medium,
} from '@icons-pack/react-simple-icons';

import { withRouter, Route, Switch } from 'react-router-dom';

import Background from './assets/background.jpg';
import LiHsuanLung from './assets/li-hsuan.lung.jpg';
import HideOnScroll from './components/HideOnScroll';
import Services from './components/Services';
import Privacy from './components/Privacy';

import theme from './theme';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  header: {
    width: '100%',
    minHeight: '100vh',
    opacity: 0.84,
  },
  hero: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    minHeight: '120vh',
    fontSize: 'calc(10px + 2vmin)',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
  },
  contact: {
    width: '40%',
    margin: '0 auto',
    padding: `${theme.spacing(8)}px 0`,

    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
  },
  services: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    fontSize: 'calc(10px + 2vmin)',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  name: {
    fontFamily: 'Playfair Display',
    fontSize: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
    fontSize: '24px',
    lineHeight: '34px',

    '& a': {
      color: theme.palette.text.primary,
    },

    '& a:hover': {
      textDecoration: 'none',
    },

    '&::first-letter': {
      fontWeight: 800,
    },
  },
  link: {
    fontSize: '16px',
    lineHeight: '19px',
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
    margin: '0 24px',
    padding: theme.spacing(1, 2),

    '&:hover': {
      textDecoration: 'none',
    },
  },
  bar: {
    padding: '0 24px',
  },
  toolbar: {
    padding: theme.spacing(1, 0, 2),
    alignItems: 'center',
  },
  headline: {
    fontFamily: 'Playfair Display',
    fontSize: theme.spacing(4),
    color: theme.palette.text.primary,
    margin: theme.spacing(50, 8, 0),

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  button: {
    color: theme.palette.text.secondary,
    border: `1px solid ${theme.palette.text.secondary}`,
    borderRadius: 0,
    padding: theme.spacing(1, 2),

    '&:hover': {
      background: 'inherit',
    },
  },
  grid: {
  },
  links: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    margin: theme.spacing(4, 0, 0),

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  icon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  social: {
    color: 'inherit',
    fontSize: theme.spacing(2),
    background: 'inherit',
    borderRadius: 0,
    textTransform: 'inherit',
    margin: theme.spacing(0, 1, 0, 0),

    '&:hover': {
      background: 'inherit',
    },

    [theme.breakpoints.down('sm')]: {
      border: 0,
    },
  },
  action: {
    color: 'inherit',
    fontSize: '16px',
    background: 'inherit',
    borderRadius: 0,
    borderSize: 2,

    '&:hover': {
      background: 'inherit',
    },
  },
  container: {
    textAlign: 'center',
    alignSelf: 'center',
    margin: theme.spacing(4, 0),
  },
  tagline: {
    fontFamily: 'Playfair Display',
    fontSize: theme.spacing(4),
    textAlign: 'center',
  },
  footer: {
    background: theme.palette.common.black,
    color: theme.palette.background.default,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '10vh',
  },
  technologies: {
    background: theme.palette.error.main,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '20vh',

    [theme.breakpoints.down('sm')]: {
      minHeight: '10vh',
      margin: theme.spacing(8, 0),
    },
  },
  technology: {
    marginRight: theme.spacing(8),
    width: theme.spacing(8),
    height: theme.spacing(8),
    color: theme.palette.background.default,

    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  },
  team: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    fontSize: 'calc(10px + 2vmin)',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  bio: {
    margin: 'auto 0',
    padding: theme.spacing(0, 16, 0, 0),
    fontSize: theme.spacing(2),
    lineHeight: '23px',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 4),
    },
  },
  avatar: {
    width: theme.spacing(40),
    height: theme.spacing(40),
    margin: '0 auto',
  },
}));

function App() {
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <HideOnScroll>
          <AppBar
            position="fixed"
            color="transparent"
            elevation={0}
            className={classes.bar}
          >
            <Toolbar className={classes.toolbar}>
              <Typography variant="h6" className={classes.title}>
                <Link href="/#hero">
                  9 Trinkets
                </Link>
              </Typography>
              {
                isMobile && (
                  <>
                  </>
                )
              }
              {
                !isMobile && (
                  <>
                    <Link
                      href="/#services"
                      className={classes.link}
                    >
                      Services
                    </Link>
                    <Link
                      href="/#team"
                      className={classes.link}
                    >
                      About
                    </Link>
                    <Link
                      href="https://lihsuanlung.medium.com/"
                      className={classes.link}
                    >
                      Blog
                    </Link>
                    <Link
                      className={clsx(classes.link, classes.button)}
                      href="mailto:hello@9trinkets.com"
                    >
                      Contact Us
                    </Link>
                  </>
                )
              }
            </Toolbar>
          </AppBar>
        </HideOnScroll>
        <Switch>
          <Route path="/privacy">
            <Privacy />
          </Route>
          <Route path="/">
            <header className={classes.header}>
              <div
                className={classes.hero}
                id="hero"
              >
                <p className={classes.headline}>
                  Beauty lies in numbers.
                </p>
              </div>
              <div
                className={classes.services}
                id="services"
              >
                <Grid container>
                  <Grid
                    xs={12}
                    sm={6}
                    className={clsx(classes.grid, classes.container)}
                    item
                  >
                    <p className={classes.tagline}>
                      We can help.
                    </p>
                    <Button
                      href="/#contact-us"
                      variant="outlined"
                      size="large"
                      disableElevation
                      className={classes.action}
                    >
                      Learn More
                    </Button>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={6}
                    className={classes.grid}
                    item
                  >
                    <Services />
                  </Grid>
                </Grid>
              </div>
              <div
                className={classes.technologies}
              >
                <Ruby className={classes.technology} />
                <Rails className={classes.technology} />
                <Python className={classes.technology} />
                <Django className={classes.technology} />
                <Javascript className={classes.technology} />
                <ReactJs className={classes.technology} />
                <Amazonaws className={classes.technology} />
                {
                  !isMobile && (
                    <>
                      <Heroku className={classes.technology} />
                      <Postgresql className={classes.technology} />
                    </>
                  )
                }
              </div>
              <div
                className={classes.team}
                id="team"
              >
                <Grid container>
                  <Grid
                    xs={12}
                    sm={6}
                    item
                    className={clsx(classes.grid, classes.container)}
                  >
                    <Avatar
                      src={LiHsuanLung}
                      className={classes.avatar}
                    />
                    <p className={classes.name}>
                      Li-Hsuan Lung, Founder
                    </p>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={6}
                    className={classes.bio}
                    item
                  >
                    <p className={classes.title}>
                      9 Trinkets is a boutique software consultancy based in Queens, New York.
                    </p>
                    <p>
                      For over a decade, Li-Hsuan has pursued the craft of delivering quality software at a sustainable pace and delighting his customers through frequent communication and transparency. His work spans across publishing, insurance, marketing, e-commerce, education, and investment banking industries.
                    </p>
                    <p>
                      In addition to writing software, Li-Hsuan offers agile coaching, and technical excellence training to teams, as well as mentorship to individuals who recently embarked on their careers in tech.
                    </p>
                    <div
                      className={classes.links}
                    >
                      <Button
                        className={classes.social}
                        variant="outlined"
                        disableElevation
                        href="https://www.linkedin.com/in/lihsuan/"
                        target="_blank"
                        startIcon={<Linkedin color="#0077B5" className={classes.icon} />}
                      >
                        { !isMobile && 'LinkedIn' }
                      </Button>
                      <Button
                        className={classes.social}
                        variant="outlined"
                        disableElevation
                        href="https://github.com/naush"
                        target="_blank"
                        startIcon={<Github color="#181717" className={classes.icon} />}
                      >
                        { !isMobile && 'GitHub' }
                      </Button>
                      <Button
                        className={classes.social}
                        variant="outlined"
                        disableElevation
                        href="https://lihsuanlung.medium.com/"
                        target="_blank"
                        startIcon={<Medium color="#12100E" className={classes.icon} />}
                      >
                        { !isMobile && 'Medium' }
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <footer
                className={classes.footer}
              >
                Copyright &copy;
                {' '}
                {new Date().getFullYear()}
                {', '}
                Nine Trinkets.
              </footer>
            </header>
          </Route>
        </Switch>
      </div>
    </ThemeProvider>
  );
}

export default withRouter(App);
