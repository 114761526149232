import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Lato',
  },
  palette: {
    primary: {
      main: '#597B80',
    },
    secondary: {
      main: '#364A4D',
    },
    text: {
      primary: '#CCCCCC',
      secondary: '#FAF759',
    },
    error: {
      main: '#A83C3C',
    },
    background: {
      default: '#E5E0E0',
    },
  },
});

export default theme;
