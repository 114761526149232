import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import PeopleIcon from '@material-ui/icons/People';
import DevicesIcon from '@material-ui/icons/Devices';
import PublicIcon from '@material-ui/icons/Public';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  name: {
    fontSize: theme.spacing(3),
    color: 'inherit',
  },
  description: {
    fontSize: theme.spacing(2),
    color: 'inherit',
  },
  icon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

function Services() {
  const classes = useStyles();

  return (
    <List>
      <ListItem>
        <ListItemIcon>
          <DevicesIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText
          primary="Application Product Development"
          secondary="Deliver beautiful software with our skilled, full-stack software developers."
          classes={{
            primary: classes.name,
            secondary: classes.description,
          }}
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <PublicIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText
          primary="Remote Pairing & Mentoring"
          secondary="Master test-driven development, pair programming and clean architecture with a personal mentor."
          classes={{
            primary: classes.name,
            secondary: classes.description,
          }}
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <PeopleIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText
          primary="Agile Coaching & Training"
          secondary="Cultivate your agile mindset and apply agile to software development with a hands-on trainer."
          classes={{
            primary: classes.name,
            secondary: classes.description,
          }}
        />
      </ListItem>
    </List>
  );
}

export default Services;
